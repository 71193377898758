<template>
  <div class="container">
    <van-card
      num="1"
      origin-price="120"
      price="1"
      desc="Goods descript"
      title="Goods Title"
      thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
    />
    <van-cell-group style="margin: 20px 0">
      <van-cell title="Goods Price" value="JPY 1" />
      <van-cell title="Shipping Price" value="JPY 0" />
      <van-cell title="Tax" value="JPY 0" />
      <van-cell title="Total" :value="`JPY ${orderPrice}`" />
    </van-cell-group>

    <van-submit-bar
      :price="100"
      button-text="Submit"
      text-align="left"
      label="Total: "
      :loading="loading"
      @submit="handlePay"
    />
  </div>
</template>

<script>
import { openCreate } from '@/apis2'

export default {
  name: 'Confirmation',
  data() {
    return {
      loading: false,
      paymentMethodType: '',
      presentmentMode: '',
      ailpayLogo: '',
      orderPrice: 1,
      brands: [],
    }
  },
  created() {},
  methods: {
    handlePay() {
      const { orderPrice } = this
      const clientOrderNo = new Date().getTime()
      this.loading = true
      openCreate({
        brandType: '02',
        clientOrderNo,
        expireTime: 7200,
        isSync: 1,
        orderPrice,
        orderSubject: 'Wechat Purchased',
        payType: '06',
        redirectUrl: 'https://apstg.colossal-tech.com/#/m/result',
      })
        .then((res) => {
          localStorage.setItem('clientOrderNo', res.clientOrderNo)
          location.href = res.codeUrl
          console.log(res)
          this.loading = false
        })
        .catch((_) => {
          this.loading = false
        })
    },
    wehchatPay() {
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: 'wx2421b1c4370ec43b',
          timeStamp: '1395712654',
          nonceStr: 'e61463f8efa94090b1f366cccfbbb444',
          package: 'prepay_id=u802345jgfjsdfgsdg888',
          signType: 'MD5',
          paySign: '70EA570631E4BB79628FBCA90534C63FF7FADD89',
        },
        // eslint-disable-next-line space-before-function-paren
        function (res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            console.log(res)
          }
        }
      )
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding-bottom: 60px;
}
.flex {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.van-cell {
  align-items: center;
}
.van-cell-group img {
  margin-right: 10px;
  height: 30px;
  &.ailpay-logo {
    height: 20px;
  }
}
.van-submit-bar__button {
  width: auto;
  padding: 0 23px;
}
.logos {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.van-submit-bar {
  background: #010101;
  color: #fff;
  .van-submit-bar__text {
    color: #fff;
  }
  .van-submit-bar__price {
    color: #f56723;
  }
  .van-button {
    background: #e24a45;
    border-radius: 0;
  }
}
</style>
